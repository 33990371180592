#boxlikes {
    display: flex;
    flex-direction: column;
    max-width: 200px;
    max-height: 200px;
    overflow: overlay;
}

#boxlikes::-webkit-scrollbar {
    width: 0px;
}

#boxlikes p {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0 0.5rem;
}

#boxlikes #avatar-likes {
    margin: 0 0.5rem 0 0;
}