.container-feedprofile {
   /* background-color: #B25E1B; */
   background-color: whitesmoke;
   width: 600px;
   min-width: 300px;
   min-height: 700px;
   max-width: 100%;
   padding: 1.5rem;
   margin: 1rem 0.2rem;
   border-radius: 1.5rem;
   -webkit-box-shadow: 9px 7px 5px rgba(50, 50, 50, 0.77);
  -moz-box-shadow:    9px 7px 5px rgba(50, 50, 50, 0.77);
  box-shadow:         9px 7px 5px rgba(50, 50, 50, 0.77);
}

.profile {
   text-align: -webkit-center;
}

.profile h1 {
   color: black
}

.inputimg input {
   display: none;
}

.profile-data {
   padding: 1.5rem;
   background-color: white;
   border-radius: 1.5rem;
   /* -webkit-box-shadow: -1px 0px 10px 0px #000000; 
   box-shadow: -1px 0px 10px 0px #000000; */
}

.profile-data * {
   display: flex;
   margin: 0.4rem;
   align-items: center;
}

.ButtonsProfile {
   padding: 1.5rem;
   display: flex;
   flex-direction: row;
   justify-content: center
}

.ButtonsProfile #button-post {
   background-color: var(--primarycolor);
   color: white;
}

#avatarphotocam {
   background-color: var(--primarycolor);
}

#photocam {
   color: white;
   width: 25px;
   height: 25px;
}
