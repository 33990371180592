#box-modal-didi {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    min-width: 320px;
    width: 500px;
    max-width: 100%;
    height: 95%;
    background: whitesmoke;
    border: 2px solid #000;
    color: rgb(0, 0, 0);
    border-radius: 1.5rem;
    padding: 1rem;
    margin: 0;
    overflow: overlay;

}


#box-modal-didi::-webkit-scrollbar {
    width: 0px;
}