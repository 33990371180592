
@media (min-width: 700px) {
    #appbar {
        background-color: white;
    }

    #appbar #menuicon {
        color: var(--primarycolor);
    }
    
    #imglogoappbar {
        width: 150px;
        max-width: 100%;
        height: auto;
    }

}


@media (max-width: 700px) {
    #appbar {
        background-color: white;
    }
    
    #appbar #menuicon {
        color: var(--primarycolor);
    }
    
    #imglogoappbar {
        width: 120px;
        max-width: 100%;
        height: auto;
    }

}


