.dropdown {
    position: absolute;
    display: inline-block;
    top: 0.4rem;
    right: 0.4rem;
}

#delete {
    color: var(--primarycolor);
    height: 15px;
}