.content-alterar {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 1rem;
    
}
.content-alterar .form {
    display: flex;
    flex-direction: column;
    margin: 1rem;
    width: 100%;
}

.content-alterar #buttons {
    background-color: var(--primarycolor);
    width: 10rem;
    height: 2rem;
}

.form #outlined-basic {
    padding: 1rem;
}

