#box-modal-create-user {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    min-width: 320px;
    height: 90%;
    max-width: 90%;
    max-height: 600px;
    background: whitesmoke;
    border: 2px solid #000;
    size: 4;
    color: rgb(0, 0, 0);
    border-radius: 1.5rem;
    padding: 1.5rem;

}

.close-modal-create #iconclose {
    position: absolute;
    top: 10px;
    right: 10px;
}

#box-modal-create-user #button-criar {
    background-color: var(--primarycolor);
    width: 50%;
    align-self: center;
}

#box-modal-create-user h1, label {
    align-self: center;
}

.close-modal-create * {
    position: relative;
}

.formulario {
    display: flex;
    flex-direction: column;
    text-align: center;
    margin-top: 2rem;
}

.formulario span {
    font-size: 0.8rem;
    color: red    
}