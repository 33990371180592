html {
  height: 100%; 
  width: 100%;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
 
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

body {
--primarycolor: #D8222A;
--secundarycolor: #FDB913;
--thirdcolor: #e4d2bc;
--quartercolor: #A7A9ac;
--postdidi: rgb(228, 228, 228);

width: 100%;
height: 100%;
background-color: var(--secundarycolor);;



}

body #root {
  width: 100%;
  height: 100%;
}

#root .App {
  width: 100%;
  height: 100%;
  overflow: overlay;
  
}

.App::-webkit-scrollbar {
  width: 0px;
}

img {
    width: 100%;
    max-height: 450px;
}