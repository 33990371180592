@media (min-width: 1000px) {
    .content-feed #variavelambiente{
        text-align: center;
    }
    .carregando-post {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;
    }

    .post-feed {
        display: flex;
        flex-direction: column;
        background-color: whitesmoke;
        padding: 1.2rem;
        border-radius: 1.5rem;
        box-shadow: 17px 16px 19px -3px rgba(0,0,0,0.1),0px 10px 15px -3px rgba(0,0,0,0.1);
    }

    .post-feed #buttonfeed{
        background-color: var(--primarycolor);
        margin: 1rem;
        align-self: end;
        width: 100px;
        max-width: 100%;
        height: 30px;
        max-height: 100%;
        
    }

    .post-feed #buttonfeed:hover {
        background-color: var(--secundarycolor);
        color: var(--primarycolor);
        
    }

    .container-id {
        display: flex;
        flex-direction: column;
        background-color: whitesmoke;
        padding: 1.2rem;
        margin: 1rem 0;
        border-radius: 1.5rem;
        position: relative;
        box-shadow: 17px 16px 19px -3px rgba(0,0,0,0.1),0px 10px 15px -3px rgba(0,0,0,0.1);
        
    }

    .container-id-didi {
        display: flex;
        flex-direction: column;
        background-color:  var(--postdidi);
        padding: 1.2rem;
        margin: 1rem 0;
        border-radius: 1.5rem;
        position: relative;
        box-shadow: 17px 16px 19px -3px rgba(0,0,0,0.1),0px 10px 15px -3px rgba(0,0,0,0.1);
        
    }


    .container-id hr,
    .container-id-didi hr {
        width: 100%;
    }

    .container-id .col1-id,
    .container-id-didi .col1-id {
        display: flex;
        flex-direction: column;
        max-width: 100%;

    }

    .content-post {
        display: flex;
        flex-direction: row;
        width: 100%;
        
    }

    .content-post h3 {
        margin: 0 1rem;
        font-size: 1.7rem;
    }

    .content-post p {
        margin: 0 1rem;
        font-size: 0.8rem;
    }

    .content-post .cargo-id {
        margin: 0 1rem;
        font-size: 1rem;
    }

    .content-post img {
        width: 85px;
        height: 85px;
        max-width: 100%;
        max-height: 100%;
        object-fit: cover;
        border-radius: 50%;
        box-shadow: 0 0.12rem 0.16rem black, 0 0.24rem 0.32rem black;

    }

    .conteudo-post img {
        width: 100%;
    }

    .conteudo-buttons {
        display: flex;
        flex-direction: row;
        justify-content: initial;
        align-items: center;
    }



}

@media (max-width: 1000px) {

    .content-feed #variavelambiente{
        text-align: center;
    }

    .carregando-post {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;
    }

    .post-feed {
        display: flex;
        flex-direction: column;
        background-color: whitesmoke;
        padding: 1.2rem;
        border-radius: 1.5rem;
        box-shadow: 17px 16px 19px -3px rgba(0,0,0,0.1),0px 10px 15px -3px rgba(0,0,0,0.1);
    }

    .post-feed #buttonfeed {
        background-color: var(--primarycolor);
        margin: 1rem;
        align-self: end;
        width: 100px;
        max-width: 100%;
        height: 30px;
        max-height: 100%;
        
    }

    .post-feed #buttonfeed:hover {
        background-color: var(--secundarycolor);
        color: var(--primarycolor);
        
    }

    .container-id {
        display: flex;
        flex-direction: column;
        background-color: whitesmoke;
        padding: 1.2rem;
        margin: 1rem 0;
        border-radius: 1.5rem;
        position: relative;
        box-shadow: 17px 16px 19px -3px rgba(0,0,0,0.1),0px 10px 15px -3px rgba(0,0,0,0.1);
        
    }

    .container-id-didi {
        display: flex;
        flex-direction: column;
        background-color: var(--postdidi);
        padding: 1.2rem;
        margin: 1rem 0;
        border-radius: 1.5rem;
        position: relative;
        box-shadow: 17px 16px 19px -3px rgba(0,0,0,0.1),0px 10px 15px -3px rgba(0,0,0,0.1);
        
    }

    .container-id hr,
    .container-id-didi hr {
        width: 100%;
    }

    .container-id .col1-id,
    .container-id-didi .col1-id {
        display: flex;
        flex-direction: column;
        max-width: 100%;

    }

    .content-post {
        display: flex;
        flex-direction: row;
        width: 100%;

    }

    .content-post h3 {
        margin: 0 1rem;
        font-size: 1.4rem;
    }

    .content-post p {
        margin: 0 1rem;
        font-size: 0.8rem;
    }

    .content-post img {
        width: 60px;
        height: 60px;
        max-width: 100%;
        max-height: 100%;
        object-fit: cover;
        border-radius: 50%;
        box-shadow: 0 0.12rem 0.16rem black, 0 0.24rem 0.32rem black;

    }


    .conteudo-post img {
        width: 100%;
    }

    .conteudo-post figure {
        margin: 0;
        padding: 0;
    }
 

    .conteudo-buttons {
        display: flex;
        flex-direction: row;
        justify-content: initial;
        align-items: center;
    }


}

.display-buttons {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    align-content: center;
}


#mousepass {
    cursor: pointer;
}

.carregar-div {
    display: flex;
    justify-content: center;
    align-self: center;
    padding: 1rem;
}

#carregar {
    background-color: var(--primarycolor);
    height: 2rem;
}

#loading {
    color: var(--primarycolor)
}