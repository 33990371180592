#modal-view-post {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    min-width: 320px;
    width: 500px;
    max-width: 90%;
    height: 90%;
    background: whitesmoke;
    border: 2px solid #000;
    color: rgb(0, 0, 0);
    border-radius: 1.5rem;
    padding: 1rem;
    margin: 0;
    
}

.container-comments {
    overflow: overlay;
    height: 90%;
}

#button-close {
    position: absolute;
    right: 1px;
    top: 1px;
}

.container-comments::-webkit-scrollbar {
    width: 0px;
}

.container-id-comments {
    display: flex;
    flex-direction: row;
    background-color: whitesmoke;
    padding: 1.2rem;
    margin: 1rem 0;
    border-radius: 1.5rem;
    position: relative;
    box-shadow: 17px 16px 19px -3px rgba(0,0,0,0.1),0px 10px 15px -3px rgba(0,0,0,0.1);
    
}

.container-id-comments-didi {
    display: flex;
    flex-direction: row;
    background-color: var(--postdidi);
    padding: 1.2rem;
    margin: 1rem 0;
    border-radius: 1.5rem;
    position: relative;
    box-shadow: 17px 16px 19px -3px rgba(0,0,0,0.1),0px 10px 15px -3px rgba(0,0,0,0.1);
    
}

.container-id-comments hr,
.container-id-comments-didi hr {
    margin: 0 0.5rem;
}

.content-comments {
    width: 60px;
    text-align: -webkit-center;
}


.text-comments h3 {
    text-align: center;
    font-size: 0.8rem;
    margin: 0 0 0.5rem 0;
}

.input-comments {
    display: flex;
    flex-direction: row;
    margin-bottom: 1.5rem;
}

#input-comments-textfield {
    width: 100%;
}

#minidata {
    font-size: 0.6rem;
    margin: 0;
}

#minicargo {
    font-size: 0.7rem;
    margin: 0 0 0.3rem 0;
}

#deletepostview {
    position: absolute;
    bottom: 5px;
    right: 2px;
}


.conteudo-buttonsview {
    display: flex;
    align-items: center;
    justify-content: space-between;
}


.conteudo-buttonsview #curtir {
    background-color: var(--primarycolor);
    height: 23px;
    width: 130px;
    font-size: 0.7rem;
    margin: 0.3rem;
} 