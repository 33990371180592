.content-user {
    display: flex;
    flex-direction: column;
    justify-content: center;
    word-break: break-word;
}

.content-user * {
    align-self: center;
    text-align: center;
}

.content-user img {
    width: 250px;
    height: 250px;
    max-width: 100%;
    max-height: 100%;
    border-radius: 50%;
    box-shadow: 0 0.12rem 0.16rem black, 0 0.24rem 0.32rem black;
}

