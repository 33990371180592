/*
 * CKEditor 5 (v34.0.0) content styles.
 * Generated on Fri, 15 Apr 2022 12:18:21 GMT.
 * For more information, check out https://ckeditor.com/docs/ckeditor5/latest/installation/advanced/content-styles.html
 */

:root {
    --ck-color-image-caption-background: hsl(0, 0%, 97%);
    --ck-color-image-caption-text: hsl(0, 0%, 20%);
    --ck-color-mention-background: hsla(341, 100%, 30%, 0.1);
    --ck-color-mention-text: hsl(341, 100%, 30%);
    --ck-color-table-caption-background: hsl(0, 0%, 97%);
    --ck-color-table-caption-text: hsl(0, 0%, 20%);
    --ck-image-style-spacing: 1.5em;
    --ck-inline-image-style-spacing: calc(var(--ck-image-style-spacing) / 2);
    --ck-todo-list-checkmark-size: 16px;
}

/* ckeditor5-basic-styles/theme/code.css */
.ck-content code {
    background-color: hsla(0, 0%, 78%, 0.3);
    padding: .15em;
    border-radius: 2px;
}
/* ckeditor5-block-quote/theme/blockquote.css */
.ck-content blockquote {
    overflow: hidden;
    padding-right: 1.5em;
    padding-left: 1.5em;
    margin-left: 0;
    margin-right: 0;
    font-style: italic;
    border-left: solid 5px hsl(0, 0%, 80%);
}
/* ckeditor5-block-quote/theme/blockquote.css */
.ck-content[dir="rtl"] blockquote {
    border-left: 0;
    border-right: solid 5px hsl(0, 0%, 80%);
}
/* ckeditor5-font/theme/fontsize.css */
.ck-content {
    & .text-tiny {
        font-size: .7em;
    }
    & .text-small {
        font-size: .85em;
    }
    & .text-big {
        font-size: 1.4em;
    }
    & .text-huge {
        font-size: 1.8em;
    }
}
/* ckeditor5-image/theme/imagecaption.css */
.ck-content .image > figcaption {
    display: table-caption;
    caption-side: bottom;
    word-break: break-word;
    color: var(--ck-color-image-caption-text);
    background-color: var(--ck-color-image-caption-background);
    padding: .6em;
    font-size: .75em;
    outline-offset: -1px;
}
/* ckeditor5-image/theme/image.css */
.ck-content {
    & .image {
        display: table;
        clear: both;
        text-align: center;
        margin: 0.9em auto;
        min-width: 50px;
        & img {
            display: block;
            margin: 0 auto;
            max-width: 100%;
            min-width: 100%;
            max-height: 450px;
        }
    }
    & .image-inline {
        /*
         * Normally, the .image-inline would have "display: inline-block" and "img { width: 100% }" (to follow the wrapper while resizing).;
         * Unfortunately, together with "srcset", it gets automatically stretched up to the width of the editing root.
         * This strange behavior does not happen with inline-flex.
         */
        display: inline-flex;
        max-width: 100%;
        align-items: flex-start;
        & picture {
            display: flex;
        }
        & picture,
        & img {
            flex-grow: 1;
            flex-shrink: 1;
            max-width: 100%;
            max-height: 450px;
        }
    }
}
/* ckeditor5-image/theme/imagestyle.css */
.ck-content {
    & .image-style-block-align-left,
    & .image-style-block-align-right {
        max-width: calc(100% - var(--ck-image-style-spacing));
    }
    & .image-style-align-left,
    & .image-style-align-right {
        clear: none;
    }
    & .image-style-side {
        float: right;
        margin-left: var(--ck-image-style-spacing);
        max-width: 50%;
    }
    & .image-style-align-left {
        float: left;
        margin-right: var(--ck-image-style-spacing);
    }
    & .image-style-align-center {
        margin-left: auto;
        margin-right: auto;
    }
    & .image-style-align-right {
        float: right;
        margin-left: var(--ck-image-style-spacing);
    }
    & .image-style-block-align-right {
        margin-right: 0;
        margin-left: auto;
    }
    & .image-style-block-align-left {
        margin-left: 0;
        margin-right: auto;
    }
    & p + .image-style-align-left,
    & p + .image-style-align-right,
    & p + .image-style-side {
        margin-top: 0;
    }
    & .image-inline {
        &.image-style-align-left,
        &.image-style-align-right {
            margin-top: var(--ck-inline-image-style-spacing);
            margin-bottom: var(--ck-inline-image-style-spacing);
        }
        &.image-style-align-left {
            margin-right: var(--ck-inline-image-style-spacing);
        }
        &.image-style-align-right {
            margin-left: var(--ck-inline-image-style-spacing);
        }
    }
}
/* ckeditor5-image/theme/imageresize.css */
.ck-content .image.image_resized {
    max-width: 100%;
    display: block;
    box-sizing: border-box;
    & img {
        width: 100%;
    }
    & > figcaption {
        display: block;
    }
}
/* ckeditor5-language/theme/language.css */
.ck-content span[lang] {
    font-style: italic;
}
/* ckeditor5-media-embed/theme/mediaembed.css */
.ck-content .media {
    clear: both;
    margin: 0.9em 0;
    display: block;
    min-width: 15em;
}
/* ckeditor5-list/theme/todolist.css */
.ck-content .todo-list {
    list-style: none;
    & li {
        margin-bottom: 5px;
        & .todo-list {
            margin-top: 5px;
        }
    }
    & .todo-list__label {
        & > input {
            -webkit-appearance: none;
            display: inline-block;
            position: relative;
            width: var(--ck-todo-list-checkmark-size);
            height: var(--ck-todo-list-checkmark-size);
            vertical-align: middle;
            border: 0;
            left: -25px;
            margin-right: -15px;
            right: 0;
            margin-left: 0;
            &::before {
                display: block;
                position: absolute;
                box-sizing: border-box;
                content: '';
                width: 100%;
                height: 100%;
                border: 1px solid hsl(0, 0%, 20%);
                border-radius: 2px;
                transition: 250ms ease-in-out box-shadow, 250ms ease-in-out background, 250ms ease-in-out border;
            }
            &::after {
                display: block;
                position: absolute;
                box-sizing: content-box;
                pointer-events: none;
                content: '';
                left: calc( var(--ck-todo-list-checkmark-size) / 3 );
                top: calc( var(--ck-todo-list-checkmark-size) / 5.3 );
                width: calc( var(--ck-todo-list-checkmark-size) / 5.3 );
                height: calc( var(--ck-todo-list-checkmark-size) / 2.6 );
                border-style: solid;
                border-color: transparent;
                border-width: 0 calc( var(--ck-todo-list-checkmark-size) / 8 ) calc( var(--ck-todo-list-checkmark-size) / 8 ) 0;
                transform: rotate(45deg);
            }
            &[checked] {
                &::before {
                    background: hsl(126, 64%, 41%);
                    border-color: hsl(126, 64%, 41%);
                }
                &::after {
                    border-color: hsl(0, 0%, 100%);
                }
            }
        }
        & .todo-list__label__description {
            vertical-align: middle;
        }
    }
}
/* ckeditor5-page-break/theme/pagebreak.css */
.ck-content .page-break {
    position: relative;
    clear: both;
    padding: 5px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    &::after {
        content: '';
        position: absolute;
        border-bottom: 2px dashed hsl(0, 0%, 77%);
        width: 100%;
    }
}
/* ckeditor5-page-break/theme/pagebreak.css */
.ck-content .page-break__label {
    position: relative;
    z-index: 1;
    padding: .3em .6em;
    display: block;
    text-transform: uppercase;
    border: 1px solid hsl(0, 0%, 77%);
    border-radius: 2px;
    font-family: Helvetica, Arial, Tahoma, Verdana, Sans-Serif;
    font-size: 0.75em;
    font-weight: bold;
    color: hsl(0, 0%, 20%);
    background: hsl(0, 0%, 100%);
    box-shadow: 2px 2px 1px hsla(0, 0%, 0%, 0.15);
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}
/* ckeditor5-table/theme/table.css */
.ck-content .table {
    margin: 0.9em auto;
    display: table;
    & table {
        border-collapse: collapse;
        border-spacing: 0;
        width: 100%;
        height: 100%;
        border: 1px double hsl(0, 0%, 70%);
        & td,
        & th {
            min-width: 2em;
            padding: .4em;
            border: 1px solid hsl(0, 0%, 75%);
        }
        & th {
            font-weight: bold;
            background: hsla(0, 0%, 0%, 5%);
        }
    }
}
/* ckeditor5-table/theme/table.css */
.ck-content[dir="rtl"] .table th {
    text-align: right;
}
/* ckeditor5-table/theme/table.css */
.ck-content[dir="ltr"] .table th {
    text-align: left;
}
/* ckeditor5-table/theme/tablecaption.css */
.ck-content .table > figcaption {
    display: table-caption;
    caption-side: top;
    word-break: break-word;
    text-align: center;
    color: var(--ck-color-table-caption-text);
    background-color: var(--ck-color-table-caption-background);
    padding: .6em;
    font-size: .75em;
    outline-offset: -1px;
}
/* ckeditor5-code-block/theme/codeblock.css */
.ck-content pre {
    padding: 1em;
    color: hsl(0, 0%, 20.8%);
    background: hsla(0, 0%, 78%, 0.3);
    border: 1px solid hsl(0, 0%, 77%);
    border-radius: 2px;
    text-align: left;
    direction: ltr;
    tab-size: 4;
    white-space: pre-wrap;
    font-style: normal;
    min-width: 200px;
    & code {
        background: unset;
        padding: 0;
        border-radius: 0;
    }
}
/* ckeditor5-horizontal-line/theme/horizontalline.css */
.ck-content hr {
    margin: 15px 0;
    height: 4px;
    background: hsl(0, 0%, 87%);
    border: 0;
}
/* ckeditor5-mention/theme/mention.css */
.ck-content .mention {
    background: var(--ck-color-mention-background);
    color: var(--ck-color-mention-text);
}
@define-mixin highlight-marker-color $color {
    /* ckeditor5-highlight/theme/highlight.css */
    .ck-content .marker-$color {
        background-color: var(--ck-highlight-marker-$color);
    }
}
@define-mixin highlight-pen-color $color {
    /* ckeditor5-highlight/theme/highlight.css */
    .ck-content .pen-$color {
        color: var(--ck-highlight-pen-$color);
        background-color: transparent;
    }
}
@media print {
    /* ckeditor5-page-break/theme/pagebreak.css */
    .ck-content .page-break {
        padding: 0;
        &::after {
            display: none;
        }
    }
}
