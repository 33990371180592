.container-administacao {
    /* background-color: #B25E1B; */
    background-color: whitesmoke;
    width: 600px;
    min-height: 700px;
    max-width: 100%;
    padding: 1.5rem;
    margin: 1rem 0.2rem;
    border-radius: 1.5rem;
    -webkit-box-shadow: 9px 7px 5px rgba(50, 50, 50, 0.77);
   -moz-box-shadow:    9px 7px 5px rgba(50, 50, 50, 0.77);
   box-shadow:         9px 7px 5px rgba(50, 50, 50, 0.77);
 }
 
 .tittle-adm {
    display: flex;
    flex-direction: column;
    text-align: center;
 }

 .buttons {
    display: flex;
    flex-direction: column;
    padding: 1.5rem;
    background-color: rgb(202, 192, 192);
    border-radius: 1.5rem;
 }

.titulo-adm{
   text-align: center;
}


.habilita-post {
   display: flex;
   justify-content: space-between
}

.vejabem-adm {
   display: flex;
   flex-direction: column;
   margin-top: 1rem;
   padding: 1.5rem;
   background-color: rgb(202, 192, 192);
   border-radius: 1.5rem;
}

#adm-button {
   background-color: var(--primarycolor);
   height: 20px;
   align-self: center;
}