#box-modal-profile-edit {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    min-width: 300px;
    max-width: 100%;
    height: 90%;
    background: whitesmoke;
    border: 2px solid #000;
    color: rgb(0, 0, 0);
    border-radius: 1.5rem;
    padding: 1rem;
    margin: 0;
    overflow: overlay;

}

#box-modal-profile-edit::-webkit-scrollbar {
    width: 0px;
}

.button-editprofile {
    padding: 1.5rem;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.button-editprofile #button-post {
    background-color: var(--primarycolor);
}

.divfilhos {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.divfilhos p {
    margin-left: 0.5rem;
    margin-right: 0.5rem;
}

.divfilhos-1 {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
}

#iconcolora {
    color: var(--primarycolor);
    font-size: 30px;
}